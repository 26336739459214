
.header {
    font-size:18px;
    font-weight:bold;
    padding:10px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
}

.header > div {
    flex:1;
}

.header > div:nth-child(2) {
    text-align: right;
}