
.splash-screen {

    width:100vw;
    height:100vh;
    background-color: #219ebc;
    display:flex;

    img {
        width:45px;
        margin-left: auto;
        margin-right: auto;
    }

}