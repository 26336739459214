@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body {
  font-family: 'Roboto', sans-serif;
  font-size:14px;
}

#root {
}

.entries-loading {
  display:block;
  text-align: center;
  font-size:14px;
}
.calendar-navigation{display:flex;align-items:center;box-sizing:border-box;padding:10px 10px 0 10px}.calendar-navigation .calendar-navigation-prev,.calendar-navigation .calendar-navigation-next{flex-grow:1;border:none;font-size:20px;flex-basis:14.2857%;background-color:inherit}.calendar-navigation .calendar-navigation-label{flex-grow:100;text-align:center;font-weight:bold}.react-calendar{padding:10px;background-color:#fff;border-radius:20px}.react-calendar button{border:0;background:none}.react-calendar .react-calendar__tile{font-size:14px;color:#444;font-weight:bold;text-align:center;background-color:#dedede;border:2px solid #fff;border-radius:10px}.react-calendar .react-calendar__tile--now{background-color:#fff}.react-calendar .react-calendar__tile--active{background-color:#219ebc;color:#fff;box-shadow:1px 5px 6px 0px #0c0c0c69;border-color:#219ebc;z-index:1000}.react-calendar .react-calendar__month-view__days__day--neighboringMonth{color:#8c8c8c;background-color:#fff}.react-calendar .react-calendar__navigation__label__labelText{font-size:18px;color:#444;font-weight:bold}.react-calendar .react-calendar__navigation__arrow{font-size:30px;color:#444;font-weight:bold}.react-calendar .react-calendar__tile--hasActive{background-color:#219ebc}.react-calendar .react-calendar__month-view__weekdays{text-align:center;font-size:12px}.react-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday{display:flex;align-items:center}.react-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr{width:100%}.react-calendar abbr[title]{text-decoration:none;color:#8c8c8c}.react-calendar .react-calendar__navigation__next2-button{display:none}.react-calendar .react-calendar__navigation__prev2-button{display:none}


.add-new-entry-btn {
    margin-bottom:10px;
    width:100%;
}

.header {
    font-size:18px;
    font-weight:bold;
    padding:10px;
    box-sizing: border-box;
    display:flex;
    align-items: center;
}

.header > div {
    flex:1 1;
}

.header > div:nth-child(2) {
    text-align: right;
}
.entry{font-size:14px;display:flex;align-items:center}.entry .entry-col1{width:80px;min-width:80px;flex-grow:1}.entry .entry-col1 .entry-time{font-weight:bold;font-size:12px;padding:4px;background-color:#219ebc;color:#fff;border-radius:4px;display:inline}.entry .entry-col2{flex-grow:100;overflow:hidden}.entry .entry-col2 .entry-title{font-weight:bold;text-transform:capitalize;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.entry .entry-col3{flex-grow:1;min-width:24px;text-align:center}.entry .entry-col3 .entry-arrow{font-size:20px;font-weight:bold;flex-basis:14.2857%;padding:0 10px}
.entry-list{width:100%;display:flex;flex-direction:column;border-radius:20px 20px 0px 0px;padding-top:10px;padding-bottom:20px;border-radius:20px}.entry-list .entry-header{display:flex;padding:10px}.entry-list .entry-header .entry-list-title{font-weight:bold;flex-grow:100}.entry-list .entry-list-content{background-color:#fff;overflow-y:scroll;box-sizing:border-box;padding:0 10px 10px 10px;height:100%;border-radius:20px}.entry-list .entry-list-no-results{text-align:center;font-size:12px}.entry-list .entry-list-count{font-size:12px;text-align:center;margin-bottom:10px}.entry-list .entry-list-loading{text-align:center;font-size:12px}.entry-list .list-group .list-group-item{background-color:#dedede;padding:10px;border:none;margin-bottom:5px}
.entry-view{height:70vh !important;border:none !important}.entry-view .offcanvas-header .offcanvas-title{font-weight:bold;font-size:20px;text-transform:capitalize}.entry-view .offcanvas-body .entry-view-date{margin-bottom:10px;font-weight:bold}.entry-view .offcanvas-body .entry-view-content{margin-bottom:10px}
.splash-screen{width:100vw;height:100vh;background-color:#219ebc;display:flex}.splash-screen img{width:45px;margin-left:auto;margin-right:auto}
