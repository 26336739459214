$backgroundColor: #fff;

$textColor: #444;
$textWeight: bold;

$tileTextSize: 14px;
$tileBackground: #dedede;
$tileBorderWidth: 2px;
$tileBorderRadius: 10px;

$selectedTileTextColor: #fff;
$selectedTileBackground: #219ebc;

$todayTileBackground: #fff;

$nextMonthTextColor: #8c8c8c;
$nextMonthBackground: #fff;

$weekdayTextColor: #8c8c8c;
$weekdayTextSize: 12px;

.calendar-navigation {
  
  display:flex;
  align-items: center;
  box-sizing: border-box;
  padding:10px 10px 0 10px;

  .calendar-navigation-prev,
  .calendar-navigation-next {
    flex-grow:1;
    border:none;
    font-size:20px;
    flex-basis: 14.2857%;
    background-color: inherit;
  }

  .calendar-navigation-label {
    flex-grow:100;
    text-align: center;
    font-weight: bold;
  }

}

.react-calendar{

    padding:10px;
    background-color:$backgroundColor;
    border-radius:20px;

    button {
      border: 0;
      background: none;
    }
    
    .react-calendar__tile {
        font-size:$tileTextSize;
        color:$textColor;
        font-weight: $textWeight;
        text-align:center;
        background-color: $tileBackground;
        border: $tileBorderWidth solid $backgroundColor;
        border-radius: $tileBorderRadius;
    }
    
    .react-calendar__tile--now {
      background-color: $todayTileBackground;
      
    }
    
    .react-calendar__tile--active {
      background-color: $selectedTileBackground;
      color:$selectedTileTextColor;
      box-shadow: 1px 5px 6px 0px #0c0c0c69;
      border-color: $selectedTileBackground;
      z-index: 1000;  
        
    }
  
    .react-calendar__month-view__days__day--neighboringMonth {        
        color: $nextMonthTextColor;
        background-color: $nextMonthBackground;
    }
  
  
    /*NAVIGATION BAR*/
 
    .react-calendar__navigation__label__labelText 
    {
      font-size:18px;
      color:$textColor;  
      font-weight:bold;
    }
  
    .react-calendar__navigation__arrow {
      font-size:30px;
      color: $textColor;
      font-weight:bold;
    }    

   
    .react-calendar__tile--hasActive {
      background-color: $selectedTileBackground;
    }
  
    /*WEEK DAY HEADINGS*/
    .react-calendar__month-view__weekdays {    
      text-align: center;
      font-size: $weekdayTextSize;
      

      .react-calendar__month-view__weekdays__weekday{
        display: flex;
        align-items: center;

        abbr {
          width:100%;
        }

      }

    }
  
    abbr[title] {
      text-decoration: none;
      color:$weekdayTextColor;
    }     
    
    .react-calendar__navigation__next2-button 
    {
      display:none;
      
    }
    
    .react-calendar__navigation__prev2-button
    {
      display:none;      
    }


}   
