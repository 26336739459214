

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@100;300;400&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size:14px;
}

#root {
}

.entries-loading {
  display:block;
  text-align: center;
  font-size:14px;
}