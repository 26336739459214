
.entry-view {

    height:70vh !important;
    border:none !important;

    .offcanvas-header {

        .offcanvas-title{
            font-weight:bold;
            font-size:20px;
            text-transform: capitalize;
        }

    }

    .offcanvas-body{

        .entry-view-date {
            margin-bottom:10px;
            font-weight:bold;
        }

        .entry-view-content {                     
            margin-bottom:10px;
        }

    }

}