
$borderRadius:20px;

.entry-list {

    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0px 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius:$borderRadius;

    .entry-header {
        display:flex;
        padding: 10px;

        .entry-list-title { 
            font-weight: bold;
            flex-grow: 100;
        }
    } 

    .entry-list-content {
        background-color: rgb(255, 255, 255);
        overflow-y: scroll;
        box-sizing: border-box;
        padding:0 10px 10px 10px;
        height:100%;
        border-radius:$borderRadius;
    }

    .entry-list-no-results {
        text-align: center;
        font-size:12px;
    }

    .entry-list-count {
        font-size:12px;
        text-align: center;
        margin-bottom:10px;
    }

    .entry-list-loading {
        text-align: center;
        font-size:12px;
    }

    .list-group {

        .list-group-item {
            background-color: #dedede;
            padding:10px;
            border:none;
            margin-bottom:5px;
        }
        
    }
   

}