.entry {
  
    font-size:14px;
    display:flex;
    align-items: center;


    .entry-col1 {
        width:80px;
        min-width:80px;
        flex-grow:1;

        .entry-time {
            font-weight: bold;
            font-size:12px;
            padding:4px;
            background-color: #219ebc;
            color:#fff;
            border-radius:4px;
            display:inline;
            
        }
    }

    .entry-col2 {

        flex-grow:100;
        overflow: hidden;

        .entry-title {
            font-weight: bold;
            text-transform: capitalize;
            overflow: hidden;
            white-space: nowrap;
            text-overflow:ellipsis;
        }
    }

    .entry-col3 {

        flex-grow:1;
        min-width: 24px;
        text-align: center;

        .entry-arrow {
            font-size:20px;
            font-weight: bold;
            flex-basis: 14.2857%;
            padding: 0 10px;
        }

    }
    
    
}